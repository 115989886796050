import { useState } from 'react';
import type { PropsWithChildren } from 'react';
import useTranslation from 'next-translate/useTranslation';

import MetaHeader from 'components/Layout/HeaderMeta';
import Footer from 'components/Layout/Footer';
import {
  DesktopNavigation,
  MobileNavigation,
} from 'components/Layout/Navigation';
import LinkBuilding from 'components/DynamicContent/LinkBuilding';

import { Layout, Settings } from 'interfaces/cms/layout';
import {
  Notification as NotificationType,
  PromotionPopupProps,
} from 'interfaces/cms/common';
import { Seo } from 'interfaces/cms/page';
import { LinkBuildingProps } from 'interfaces/cms/content';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import Breadcrumb from 'components/DynamicContent/Breadcrumb';
import { DynamicContentTypes } from 'types/cms';
import LayoutHeaderV2 from './Header/V2';

const NotificationBar = dynamic(
  () => import('components/Layout/NotificationBar')
);
const PromotionPopup = dynamic(() => import('components/PromotionPopupMenu'));
const CookieBanner = dynamic(() => import('./CookieBanner'));

type LayoutProps = {
  layout: Layout;
  notifications: Array<NotificationType>;
  popup: PromotionPopupProps;
  settings: Settings;
  seo: Seo;
  slug: string;
  isMultipleLang: boolean;
  staticLinkBuilding: LinkBuildingProps;
  breadcrumbs: Array<{ text: string; url: string }> | [];
};

function LayoutComponent({
  layout,
  seo,
  settings,
  notifications,
  popup,
  slug,
  isMultipleLang,
  staticLinkBuilding,
  breadcrumbs = [],
  children,
}: PropsWithChildren<LayoutProps>) {
  const [openNavigation, setOpenNavigation] = useState(false);

  const { t } = useTranslation('common');

  const cookieBannerData = {
    content: t('cookieBannerContent'),
    btnLabel: t('cookieBannerBtnLabel'),
  };

  const toggleNavigation = () => setOpenNavigation(!openNavigation);

  return (
    <>
      <MetaHeader {...seo} />

      <LayoutHeaderV2
        {...settings}
        locale={layout.language?.code || ''}
        slug={slug}
        navigationIsOpen={openNavigation}
        toggleNavigation={toggleNavigation}
        isMultipleLang={isMultipleLang}
      />
      <CookieBanner
        content={cookieBannerData.content}
        btnLabel={cookieBannerData.btnLabel}
      />
      <div
        className={clsx(
          'bg-white z-50 overflow-y-scroll',
          'fixed left-0 top-[3.75rem] w-full',
          'transform transition-all ease-in-out duration-300',
          openNavigation ? 'translate-x-0' : '-translate-x-full'
        )}
        id="side-bar"
        style={{ height: 'calc(100vh - 60px)' }}
      >
        {openNavigation && (
          <MobileNavigation
            navigations={layout.navigation || []}
            slug={slug}
            locale={layout.language?.code || ''}
            isMultipleLang={isMultipleLang}
            isOpen={openNavigation}
            onClick={toggleNavigation}
          />
        )}
      </div>
      <div className="sticky top-[3.75rem] z-20">
        <DesktopNavigation
          navigations={layout.navigation || []}
          locale={layout.language?.code || ''}
        />
        <NotificationBar {...{ notifications }} />
      </div>

      {popup && <PromotionPopup {...popup} />}
      {breadcrumbs.length > 0 && (
        <Breadcrumb
          componentType={DynamicContentTypes.BREADCRUMB}
          id={0}
          items={breadcrumbs.map((b, i) => ({
            id: i,
            name: b.text,
            urlPath: b.url,
          }))}
        />
      )}
      <div className="min-h-screen mx-auto my-0 p-0 flex flex-col justify-center items-center">
        {children}
      </div>

      {(staticLinkBuilding?.section?.length > 0 ||
        staticLinkBuilding?.header) && <LinkBuilding {...staticLinkBuilding} />}

      {layout.footer && (
        <Footer {...layout.footer} locale={layout.language?.code || ''} />
      )}
    </>
  );
}

export default LayoutComponent;
