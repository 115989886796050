import { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';

import Markdown from 'components/common/Markdown';
import { ParagraphHeaderProps } from 'interfaces/cms/common';
import ProductTypesContext from '../../store/productTypes-context';
import styles from './ParagraphHeader.module.scss';

function ParagraphHeader({
  product,
  productColor,
  title,
  titleColor,
  subtitle,
  subtitleColor,
  align = 'center',
  line = false,
  lineColor,
}: ParagraphHeaderProps) {
  const ctx = useContext(ProductTypesContext);
  const [productName, setProductName] = useState('');
  const itemsPosition = {
    center: 'items-center',
    left: 'items-start',
    right: 'items-end',
  };

  useEffect(() => {
    if (product) {
      const matchedProduct = ctx.find(
        (item: { id: string }) => item.id === product
      );
      setProductName(matchedProduct?.productName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={clsx(
        'max-w-[69.375rem] mx-auto',
        'flex flex-col',
        itemsPosition[align]
      )}
    >
      {productName && (
        <span
          className={clsx(
            'font-bold uppercase text-sm',
            'mb-[0.625rem] tracking-[0.175rem]'
          )}
          style={{ color: productColor }}
        >
          {productName}
        </span>
      )}

      {title && (
        <div className={`${styles.paragraph_header__title}`}>
          <Markdown md={title} textAlign={align} textColor={titleColor} />
        </div>
      )}

      {subtitle && (
        <span className="mt-[0.625rem]" style={{ color: subtitleColor }}>
          {subtitle}
        </span>
      )}

      {line && (
        <span
          className="w-[8.125rem] border-t-[4px] mt-[1.875rem]"
          style={{ borderTopColor: lineColor }}
        ></span>
      )}
    </div>
  );
}

export default ParagraphHeader;
