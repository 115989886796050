import BaseImage, { ImageLoaderProps, ImageProps } from 'next/image';
import ImgixClient from '@imgix/js-core';
import placeholder from './placeholder';

const isProd = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';

const imgixBase = process.env.imgixBase || '';
const imgixDomain = process.env.imgixDomain || '';

const imageLoader = ({ src, quality }: ImageLoaderProps) => {
  const client = new ImgixClient({ domain: imgixDomain });

  const config = {
    auto: 'compress,format',
    fit: 'clip',
    q: 75,
  };

  if (quality) {
    config.q = quality;
  }

  const path = src.replace(imgixBase, '');
  return client.buildURL(path, config);
};

function Image({ ...props }: ImageProps) {
  if (isProd) {
    return <BaseImage {...placeholder(props)} loader={imageLoader} />;
  }

  return <BaseImage {...placeholder(props)} />;
}

export default Image;
