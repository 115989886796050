/// <reference types="@next/third-parties/google" />

import { Settings } from 'interfaces/cms/layout';

export const getGtmId = (settings?: Settings) => {
  return settings?.gtmContainer || process.env.NEXT_PUBLIC_GTM;
};

class GTMHelper {
  static pageView = (title: string, page: string) =>
    this.customEvent('VirtualPageView', {
      title,
      page,
    });

  static customEvent = (event: string, params = {}) =>
    window.dataLayer?.push({
      event,
      ...params,
    });

  static customCategory = (eventCategory: string, params = {}) =>
    window.dataLayer?.push({
      eventCategory,
      ...params,
    });

  static customRcHomeCategory = (rcHomeEventCategory: string, params = {}) =>
    window.dataLayer?.push({
      rcHomeEventCategory,
      ...params,
    });

  static clickRcHomeCategory = (rcHomeEventCategory: string, params = {}) =>
    window.dataLayer?.push({
      event: 'gtm.click',
      rcHomeEventCategory,
      ...params,
    });
}

export default GTMHelper;
